<template>
  <div class="px-2 md:mx-auto mt-8">
    <t-table class="w-full mt-5" :headers="scenarioHeader" :data="scenarioList">
      <template slot="row" slot-scope="props">
        <tr class="hover:bg-gray-100">
          <td class="pl-3">
            {{ props.row.name }}
          </td>
          <td class="flex justify-end px-5">
            <t-button
              class="border-2 text-xs rounded-xs m-2"
              variant="primary"
              @click="editScenario(props.row.id)"
            >
              <i class="fas fa-pencil-alt text-xs"></i>
            </t-button>
            <t-button
              class="border-2 text-xs rounded-xs m-2"
              variant="danger"
              @click="openScenarioModal(props.row, 'delete')"
            >
              <i class="fas fa-trash"></i>
            </t-button>
          </td>
        </tr>
      </template>
    </t-table>
    <scenario-modal
      v-model="scenarioModal"
      :scenario="scenarioToDelete"
      @delete="deleteScenario"
    ></scenario-modal>
  </div>
</template>

<script>
import ScenarioModal from "@/components/ScenarioModal.vue";
import { mapActions, mapGetters } from "vuex";
import { actions, getters } from "@/constants/state";

export default {
  components: { ScenarioModal },

  data: () => ({
    loading: false,
    scenarioModal: false,
    scenarioToDelete: {},
  }),

  mounted() {
    this.fetchScenarios();
  },

  methods: {
    ...mapActions({
      getScenarios: actions.SCENARIOS_FETCH_OVERVIEW_ACTION,
      createScenario: actions.SCENARIOS_CREATE_ACTION,
      editScenario: actions.SCENARIOS_UPDATE_ACTION,
      removeScenario: actions.SCENARIOS_REMOVE_ACTION,
    }),

    async fetchScenarios() {
      this.loading = true;

      try {
        await this.getScenarios();
      } catch (error) {
        console.error(error);
      }

      this.loading = false;
    },

    async openScenarioModal(scenario, action = null) {
      this.scenarioModal = true;

      if (action === "delete") {
        this.scenarioToDelete = scenario;
      }
    },

    editScenario(id) {
      this.$router.push(`/manager/scenarios/scenario-shifts/${id}`);

      return;
    },

    async deleteScenario(id) {
      this.loading = true;

      try {
        await this.removeScenario(id);

        this.scenarioModal = false;
      } catch (error) {
        console.error(error);
      }

      this.loading = false;
    },
  },

  computed: {
    ...mapGetters({
      scenarioList: getters.SCENARIOS_OVERVIEW_GETTER,
    }),

    scenarioHeader() {
      return [
        {
          value: "name",
          text: "Name",
          className: "text-left",
        },
        {
          value: "action",
          text: "",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  width: 150px;
}

.delete-text {
  font-size: 20px;
  color: rgb(248, 4, 4);
  font-weight: 500;
}
</style>
