<template>
  <t-modal :value="value" v-on="$listeners" header="Confirm delete">
    <div class="flex m-5">
      <label>
        {{ $t("delete_confirmation") }}
        <b>{{ scenario.name }}</b> ?
      </label>
    </div>
    <template v-slot:footer>
      <div class="row-span-1 flex justify-end">
        <t-button
          class="text-sm rounded-md mx-1"
          @click="$emit('input', false)"
        >
          Cancel
        </t-button>
        <t-button
          class="text-sm rounded-md mx-1"
          variant="danger"
          @click="$emit('delete', scenario.id)"
        >
          Delete
        </t-button>
      </div>
    </template>
  </t-modal>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    scenario: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.delete-text {
  font-size: 35px;
  font-weight: 700;
}

button {
  height: 40px;
}
</style>